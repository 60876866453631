export var sticky = function () {
// select all the blocks with data-attribute "sticky"
    const elements = document.querySelectorAll( `[data-sticky]` )

    for ( let i = 0; i < elements.length; i++ ) {
        ( function ( element ) {

            // offset value from a top of the page
            const offset = element.offsetTop
            // set element height
            const elemHeight = element.offsetHeight
            // set offset value
            const variableOffset = parseInt( element.getAttribute( `data-sticky-offset` ), 10 ) || offset
            // set element padding-top
            const variablePadding = parseInt( element.getAttribute( `data-sticky-padding` ), 10 )
            // set minimal viewport to activate sticky behavior
            const variableViewport = parseInt( element.getAttribute( `data-sticky-viewport` ), 10 )

            // check if sticky behavior in needed only in range of height of some other element (column i.e.)
            const rangedScrollContainer = element.getAttribute( `data-sticky-container` )
            const rangedScrollContainerExist = document.querySelector( rangedScrollContainer )

            let rangedScrollContainerHeight = 0

            if ( rangedScrollContainerExist ) {
                // set container height to range the scroll
                rangedScrollContainerHeight = rangedScrollContainerExist.offsetHeight
            }

            // sticky element dimensions (height, width, .etc)
            const rect = element.getBoundingClientRect()
            const offsetToUse = variableOffset

            const getStick = function () {
                element.classList.add( `stick` )
                element.style.position = `fixed`
                element.style.top = `0`
                element.style.width = parseInt( rect.width, 10 ) + `px`
            }

            const getUnstick = function () {
                element.classList.remove( `stick` )
                element.style.position = `initial`
                element.style.top = `inherit`
                element.style.width = `inherit`
            }

            const getStickInRange = function () {
                element.classList.add( `stick` )
                element.style.position = `relative`
                // opposite column height minus element's height and minus padding top
                element.style.top = rangedScrollContainerHeight - rect.height - variablePadding + `px`
                element.style.width = parseInt( rect.width, 10 ) + `px`
            }

            const stick = function () {
                // scroll from a top of the page
                const scroll = window.scrollY
                const windowWidth = window.innerWidth
                // check if element already has a class
                const isStickied = element.classList.contains( `stick` )

                if ( windowWidth > variableViewport ) {
                    if ( scroll >= offsetToUse && !isStickied ) {
                        getStick()
                    } else if ( scroll <= offsetToUse && isStickied ) {
                        getUnstick()
                    } else if ( scroll >= ( rangedScrollContainerHeight + offsetToUse - elemHeight ) && rangedScrollContainerExist ) {
                        getStickInRange()
                    } else if ( scroll <= ( rangedScrollContainerHeight + offsetToUse - elemHeight ) && isStickied && rangedScrollContainerExist ) {
                        getStick()
                    }
                }

            }
            // listen to the scroll events
            window.addEventListener( `scroll`, stick )

        } )( elements[ i ] )
    }
}
