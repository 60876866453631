export var modals = function () {

    const modalButtons = document.querySelectorAll( `.js-open-modal` )
    const overlay = document.querySelector( `.js-overlay-modal` )
    const body = document.querySelector( `body` )
    const closeButtons = document.querySelectorAll( `.js-modal-close` )

    modalButtons?.forEach( function ( item ) {
        if ( item.getAttribute( 'listener' ) !== 'true' ) {
            item.addEventListener( `click`, function ( e ) {
                e.preventDefault()
                e.target.setAttribute( 'listener', 'true' )
                const modalId = this.getAttribute( `data-modal` )
                const modalElem = document.querySelector( `.modal[data-modal="` + modalId + `"]` )
                modalElem?.classList.add( `active` )
                overlay?.classList.add( `active` )
                body.classList.add( `faded-content` )
            } )
        }
    } )

    closeButtons?.forEach( function ( item ) {
        if ( item.getAttribute( 'listener' ) !== 'true' ) {
            item.addEventListener( `click`, function ( e ) {
                e.target.setAttribute( 'listener', 'true' )
                const parentModal = item.closest( `.modal` )
                parentModal?.classList.remove( `active` )
                overlay?.classList.remove( `active` )
                body.classList.remove( `faded-content` )
            } )
        }
    } )

    if ( document.body.getAttribute( 'listener' ) !== 'true' ) {
        document.body.addEventListener( `keyup`, function ( event ) {
            if ( event.code === `Escape` ) {
                document.body.setAttribute( 'listener', 'true' )
                document.querySelector( `.modal.active` )?.classList.remove( `active` )
                overlay?.classList.remove( `active` )
                body.classList.remove( `faded-content` )
            }
        }, false )
    }

    if ( overlay?.getAttribute( 'listener' ) !== 'true' ) {
        overlay?.addEventListener( `click`, function () {
            overlay.setAttribute( 'listener', 'true' )
            document.querySelector( `.modal.active` )?.classList.remove( `active` )
            body.classList.remove( `faded-content` )
            overlay?.classList.remove( `active` )
        } )
    }
}
