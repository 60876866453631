import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router';

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        chosenItem: '',
        email: '',
        firstName: '',
        phone: '',
        locale: ( JSON.parse(window.localStorage.getItem('selectedLocale')) ) ?? { language: 'ru-RU' }
    },
    getters: {
        LOCALE: state => {
            return state.locale
        }
    },
    mutations: {
        setChosenItem (state, payload) {
            state.chosenItem = payload
        },
        setFirstName (state, payload) {
            state.firstName = payload.firstName
        },
        setEmail (state, payload) {
            state.email = payload.email
        },
        setPhone (state, payload) {
            state.phone = payload.phone
        },
        setLocale (state, payload) {
            state.locale = payload
            window.localStorage.setItem('selectedLocale', JSON.stringify(state.locale))
        }
    },
    actions: {
        async toggleLocale (context, payload) {
            context.commit('setLocale', payload)
        },
        async getCountry () {
            fetch('https://api.ipregistry.co/?key=tryout')
                .then(function (response) {
                    return response.json();
                })
                .then(function (payload) {
                    console.log(payload.location.country.name + ', ' + payload.location.city);
                    if ( payload.location.country.name !== 'Russian Federation' ) router.push('/?lang=en')
                });
        },
        async getLocale (context) {
            const queryString = window.location.search
            const urlParams = new URLSearchParams(queryString).get('lang')
            if ( urlParams )
                context.commit('setLocale', { language: urlParams })
        }
    },
    modules: {}
})
