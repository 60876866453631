<template>
    <div class="header">
        <MainMenuComp :menu="menu"></MainMenuComp>
        <div class="container">
            <div class="header__bottom">
                <a href="/" class="header__logo">
                    <img src="@/assets/svg/grenada.svg" alt=""/>
                    {{ global.subtitle }}
                </a>
                <div class="header__contacts">
                    <a :href="'tel:' + global.phone" class="header__phone">{{ global.phone }}</a>
                    <a href="javascript:void(0)" class="header__button btn btn--primary js-open-modal"
                       data-modal="callback">{{ msg[lang].button }}</a>
                    <div class="header__languages">
                        <a href="javascript:void(0)" class="header__languages-link" data-lang="en"
                           :class="(lang === 'en') ? 'current' : ''"
                           @click="changeLocale('en')">EN</a>
                        <a href="javascript:void(0)" class="header__languages-link" data-lang="ru-RU"
                           :class="(lang === 'ru-RU') ? 'current' : ''"
                           @click="changeLocale('ru-RU')">RU</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MainMenuComp from "@/components/MainMenuComp"

import { store } from "@/store"

export default {
    name: "HeaderComp",
    components: { MainMenuComp },
    props: {
        global: {
            logotype: {
                url: '',
            },
            phone: '',
            subtitle: '',
        },
    },
    data () {
        return {
            lang: store.getters.LOCALE.language,
            msg: {
                'ru-RU': {
                    button: 'Консультация бесплатно',
                },
                'en': {
                    button: 'Free consultation'
                }
            },
            menu: {
                links: {
                    'ru-RU': [
                        [ 'Общая информация', '#country' ],
                        [ 'Достопримечательности', '#showplaces' ],
                        [ 'Преимущества CBI', '#advantages' ],
                        [ 'Процесс получения', '#citizenship' ],
                        [ 'Варианты инвестирования', '#variants' ],
                        [ 'Контакты', '#feedback' ],
                    ],
                    'en': [
                        [ 'General information', '#country' ],
                        [ 'Sights', '#showplaces' ],
                        [ 'Benefits of CBI', '#advantages' ],
                        [ 'Obtaining process', '#citizenship' ],
                        [ 'Investment options', '#variants' ],
                        [ 'Contacts', '#feedback' ],
                    ],
                },
                'type': 'transparent'
            }
        }
    },
    methods: {
        changeLocale: function ( language ) {
            store.dispatch( 'toggleLocale', {
                language
            } )

            document.location.reload()
        },
    }
}
</script>

<style scoped>

</style>
