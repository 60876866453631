<template>
    <div id="app">
        <transition name="fade">
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>

export default {
    name: 'App',
}
</script>

<style lang="scss">
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition-property: opacity;
    transition-duration: 0.33s;
}

.fade-enter-active {
    transition-delay: 0.33s;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}
</style>
