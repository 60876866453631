<template>
    <section class="tourism">
        <div class="container">
            <div class="tourism__grid d-grid">
                <div class="tourism__map">
                    <img :src="$api_url + tourism.mainPicture.url" alt=""/>
                </div>
                <div class="tourism__activities d-grid">
                    <div v-for="item in tourism.content" :key="item.id" class="tourism__activity">
                        <img :src="$api_url + item.icon.url" class="tourism__activity-icon" alt="">
                        <div class="tourism__activity-name">{{ item.title }}</div>
                        <div class="tourism__activity-text">{{ item.paragraph }}</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios"

import { store } from "@/store";

export default {
    name: "CountryComp",
    data () {
        return {
            tourism: {
                mainPicture: {
                    url: '',
                },
            },
            error: null,
        }
    },
    async beforeCreate () {
        try {
            await axios
                .get( this.$api_url + '/tourism?_locale=' + store.getters.LOCALE.language )
                .then( response => ( this.tourism = response.data ) )
        } catch ( error ) {
            this.error = error;
        }
    }
}
</script>

<style scoped>

</style>
