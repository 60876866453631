<template>
    <section class="country" id="country">
        <div class="container-fluid">
            <div class="country__grid d-grid">
                <div class="country__block">
                    <div class="country__header d-flex">
                        <img src="@/assets/svg/grenada.svg" alt=""/>
                        <h1 class="country__title h2">{{ country.Country }}</h1>
                    </div>
                    <div class="country__text text" v-html="country.aboutCountry"></div>
                    <div class="country__facts d-flex">
                        <div v-for="fact in country.numbers" :key="fact.id" class="country__fact">
                            <div class="country__fact-number" v-html="fact.title"></div>
                            <div class="country__fact-text" v-html="fact.paragraph"></div>
                        </div>
                    </div>
                </div>
                <div v-if="country" class="country__gallery">
                    <div v-for="photo in country.gallery" :key="photo.id" class="country__photo">
                        <img :src="$api_url + photo.formats.large.url" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { tns } from 'tiny-slider/src/tiny-slider.module'

import { store } from "@/store"

import axios from "axios";

export default {
    name: "CountryComp",
    data () {
        return {
            country: {
                flag: {},
                Country: '',
                aboutCountry: '',
                numbers: {},
                gallery: {},
            },
            error: null,
        }
    },
    async beforeCreate () {
        try {
            await axios
                .get( this.$api_url + '/country?_locale=' + store.getters.LOCALE.language )
                .then( response => ( this.country = response.data ) )

            tns( {
                "container": `.country__gallery`,
                "autoWidth": false,
                "controls": false,
                "nav": true,
                "navPosition": `bottom`,
                "speed": 400,
                "mouseDrag": true,
                "arrowKeys": true,
            } )

        } catch ( error ) {
            this.error = error;
        }
    }
}
</script>

<style scoped>

</style>
