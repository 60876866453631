<template>
    <div class="landing">
        <HeaderComp :global="global"></HeaderComp>
        <HeroComp></HeroComp>
        <SuperiorityComp></SuperiorityComp>
        <CountryComp></CountryComp>
        <TourismComp></TourismComp>
        <ShowplacesComp></ShowplacesComp>
        <AdvantagesComp></AdvantagesComp>
        <CitizenshipComp></CitizenshipComp>
        <VariantsComp></VariantsComp>
        <FeedbackFormComp></FeedbackFormComp>
        <FooterComp :global="global"></FooterComp>
        <ModalsComp></ModalsComp>
    </div>
</template>

<script>
import axios from 'axios';

import { store } from '@/store'

import HeaderComp from '@/components/HeaderComp.vue'
import HeroComp from '@/components/HeroComp.vue'
import SuperiorityComp from '@/components/SuperiorityComp.vue'
import CountryComp from '@/components/CountryComp.vue'
import TourismComp from '@/components/TourismComp.vue'
import ShowplacesComp from '@/components/ShowplacesComp.vue'
import AdvantagesComp from '@/components/AdvantagesComp.vue'
import CitizenshipComp from '@/components/CitizenshipComp.vue'
import VariantsComp from '@/components/VariantsComp.vue'
import FeedbackFormComp from '@/components/FeedbackFormComp.vue'
import FooterComp from '@/components/FooterComp.vue'
import ModalsComp from '@/components/ModalsComp.vue'

export default {
    name: 'LandingPage',
    components: {
        HeroComp,
        HeaderComp,
        SuperiorityComp,
        CountryComp,
        TourismComp,
        ShowplacesComp,
        AdvantagesComp,
        CitizenshipComp,
        VariantsComp,
        FeedbackFormComp,
        FooterComp,
        ModalsComp
    },
    data () {
        return {
            global: {
                logotype: {
                    url: ''
                },
                phone: '',
                subtitle: '',
                defaultSeo: {}
            },
            error: null
        }
    },
    beforeCreate () {
        store.dispatch('getCountry').then(store.dispatch('getLocale'))
    },
    async beforeMount () {
        try {
            await axios
                .get(this.$api_url + '/global?_locale=' + store.getters.LOCALE.language)
                .then(response => ( this.global = response.data ))
        } catch ( error ) {
            this.error = error;
        }
    },
};
</script>
