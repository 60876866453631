<template>
    <div>
        <footer class="footer">
            <div class="container">
                <div class="footer__grid d-grid">
                    <div class="footer__left">
                        <a href="/" class="logotype">{{ global.siteName }}</a>
                    </div>
                    <div class="footer__text">
                        <div class="footer__text-name">{{ global.siteName }}</div>
                        <div class="footer__text-description" v-html="global.defaultSeo.metaDescription"></div>
                    </div>
                    <div class="footer__menu">
                        <a v-for="link in menu.links[lang]" :key="link.id" :href="link[1]"
                           class="footer__menu-link">{{ link[ 0 ] }}</a>
                    </div>
                    <div class="footer__contacts">
                        <a :href="'tel:'+global.phone" class="footer__phone">{{ global.phone }}</a>
                        <!--<div class="footer__address">{{ global.address }}</div>-->
                        <a href="javascript:void(0)" class="btn btn--secondary js-open-modal" data-modal="callback">
                            {{ msg[ lang ].button }}</a>
                    </div>
                </div>
            </div>
        </footer>
        <section class="bottom-line">
            <div class="container">
                <div class="bottom-line__content d-flex">
                    <div class="bottom-line__copyrights">© 2022. AKT Global Migration Solutions Limited</div>
                    <div class="bottom-line__copyrights">Designed by
                        <a target="_blank" href="https://champer.ru">Champer Digital Agency</a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { store } from '@/store';

export default {
    name: 'FooterComp',
    props: {
        global: {
            defaultSeo: {},
            siteName: '',
            phone: '',
            address: ''
        }
    },
    data () {
        return {
            lang: store.getters.LOCALE.language,
            msg: {
                'ru-RU': {
                    button: 'Обратная связь'
                },
                'en': {
                    button: 'Contact us'
                }
            },
            menu: {
                links: {
                    'ru-RU': [
                        [ 'Общая информация', '#country' ],
                        [ 'Достопримечательности', '#showplaces' ],
                        [ 'Преимущества CBI', '#advantages' ],
                        [ 'Процесс получения', '#citizenship' ],
                        [ 'Варианты инвестирования', '#variants' ],
                        [ 'Контакты', '#feedback' ]
                    ],
                    'en': [
                        [ 'General information', '#country' ],
                        [ 'Sights', '#showplaces' ],
                        [ 'Benefits of CBI', '#advantages' ],
                        [ 'Obtaining process', '#citizenship' ],
                        [ 'Investment options', '#variants' ],
                        [ 'Contacts', '#feedback' ]
                    ]
                },
                'type': 'transparent'
            }
        }
    }
}
</script>

<style scoped>

</style>
