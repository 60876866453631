<template>
    <section class="hero">
        <div v-if="homepage.hero.picture" class="hero__background">
            <img :src="$api_url + homepage.hero.picture.url" alt=""/>
        </div>
        <div class="container">
            <div class="hero__content">
                <div class="hero__title" v-html="homepage.hero.title"></div>
                <div class="hero__subtitle" v-html="homepage.hero.subTitle"></div>
                <a href="javascript:void(0)" class="hero__button btn btn--lg btn--secondary js-open-modal"
                   data-modal="callback">{{ homepage.hero.buttonText }}</a>
            </div>
            <img :src="$api_url + homepage.hero.picturePNG.url" alt="" class="hero__object"/>
        </div>
    </section>
</template>

<script>
import axios from "axios"
import { store } from "@/store"

export default {
    name: "HeroComp",
    data () {
        return {
            homepage: {
                hero: {
                    picture: {
                        url: '/uploads/bg_header_767322162e.jpg',
                    },
                    picturePNG: {
                        url: '',
                    },
                    title: 'Гражданство Гренады',
                    subTitle: '',
                    buttonText: 'Подробнее об условиях'
                }
            },
            error: null,
        }
    },
    created () {
        try {
            axios
                .get( this.$api_url + '/homepage?_locale=' + store.getters.LOCALE.language )
                .then( response => ( this.homepage = response.data ) )
        } catch ( error ) {
            this.error = error;
        }
    }
}
</script>

<style scoped>

</style>
