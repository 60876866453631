<template>
    <section id="showplaces" class="showplaces">
        <div class="container">
            <h3 class="showplaces__article text-center h3">{{ showplaces.article }}</h3>
            <div class="showplaces__preview text text-center">{{ showplaces.subText }}</div>
            <div class="showplaces__carousel">
                <div v-for="item in showplaces.sights" :key="item.id" class="showplaces__item">
                    <div class="showplaces__photo">
                        <img :src="$api_url + item.icon.url" alt=""/>
                        <div class="showplaces__name">{{ item.title }}</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { tns } from 'tiny-slider/src/tiny-slider.module'

import axios from "axios"

import { store } from "@/store";

export default {
    name: "ShowplacesComp",
    data () {
        return {
            showplaces: {
                article: '',
                subText: '',
                sights: {},
            },
            error: null,
        }
    },
    async beforeCreate () {
        try {
            await axios
                .get( this.$api_url + '/showplaces?_locale=' + store.getters.LOCALE.language )
                .then( response => ( this.showplaces = response.data ) )

            tns( {
                "container": `.showplaces__carousel`,
                "autoWidth": false,
                "controls": false,
                "nav": true,
                "navPosition": `bottom`,
                "speed": 400,
                "mouseDrag": true,
                "arrowKeys": true,
                "items": 4,
                "gutter": 48,
                "responsive": {
                    "1200": {
                        "items": 4,
                        "gutter": 32,
                    },
                    "991": {
                        "items": 3,
                        "gutter": 16,
                    },
                    "768": {
                        "items": 2,
                        "gutter": 32,
                    },
                    "0": {
                        "items": 1,
                        "gutter": 24,
                        "edgePadding": 24
                    }
                }
            } )

        } catch ( error ) {
            this.error = error;
        }
    }
}
</script>

<style scoped>

</style>
