<template>
    <section class="superiority">
        <div class="container">
            <div class="superiority__grid d-grid">
                <div v-for="superior in superiority.content" :key="superior.id"
                     class="superiority__item card card--center">
                    <img class="superiority__icon" :src="$api_url + superior.icon.url" alt=""/>
                    <div class="superiority__title">{{ superior.title }}</div>
                    <div class="superiority__paragraph">{{ superior.paragraph }}</div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios"
import { store } from "@/store"

export default {
    name: "SuperiorityComp",
    data () {
        return {
            superiority: {
                title: '',
                paragraph: '',
            },
            error: null,
        }
    },
    beforeMount () {
        try {
            axios
                .get( this.$api_url + '/superiority?_locale=' + store.getters.LOCALE.language )
                .then( response => ( this.superiority = response.data ) )
        } catch ( error ) {
            this.error = error;
        }
    }
}
</script>

<style scoped>

</style>
