<template>
    <section id="variants" class="variants">
        <div class="container">
            <div class="variants__content d-grid">
                <div class="variants__block">
                    <h3 class="variants__article h3">{{ variants.article }}</h3>
                    <div class="variants__paragraph text" v-html="variants.paragraph"></div>
                </div>
                <div class="variants__cards d-grid">
                    <div v-for="variant in variants.offers" :key="variant.id"
                         class="variants__card card card--nopadding">
                        <img class="variants__card-image" :src="$api_url + variant.image.url"
                             alt=""/>
                        <div class="variants__card-body">
                            <div class="variants__card-name">{{ variant.article }}</div>
                            <div class="variants__card-cost">{{ variant.cost }} $</div>
                            <div class="variants__card-subtext">{{ variant.subArticle }}</div>
                            <div class="variants__card-info" v-html="variant.description"></div>
                            <a href="javascript:void(0)" data-modal="callback"
                               class="variants__card-button btn btn--secondary js-open-modal">{{
                                    variant.buttonText
                                                                                              }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import { modals } from '@/assets/js/modals'
import { store } from "@/store";

export default {
    name: "VariantsComp",
    data () {
        return {
            variants: {
                article: '',
                paragraph: '',
                offers: {}
            },
            error: null,
        }
    },
    async beforeCreate () {
        try {
            await axios
                .get( this.$api_url + '/variants?_locale=' + store.getters.LOCALE.language )
                .then( response => ( this.variants = response.data ) )

            modals()

        } catch ( error ) {
            this.error = error;
        }
    },
}
</script>

<style scoped>

</style>
