<template>
    <section id="feedback" class="feedback">
        <div class="container">

            <form @submit.prevent="sendFeedback" class="form form--white">
                <h4 class="feedback__article text-center h4">{{ msg[ lang ].subtitle }}</h4>
                <div class="feedback__text text-center">{{ msg[ lang ].paragraph }}</div>

                <div class="message message--callback d-none"></div>

                <div v-if="info.sent === false" class="form__wrapper">

                    <div class="form__group">
                        <label for="form__name" class="form__label">{{ msg[ lang ].label_name }}</label>
                        <input v-model="info.name" type="text" id="form__name" class="form__input" name="name" value=""
                               required autocomplete="off">
                    </div>
                    <div class="form__group">
                        <label for="form__phone" class="form__label">{{ msg[ lang ].label_phone }}</label>
                        <input v-model="info.phone" type="text" id="form__phone" class="form__input" name="phone"
                               value="" required placeholder="+7 (495) ..." autocomplete="off">
                    </div>
                    <div class="form__group">
                        <label for="form__email" class="form__label">E-mail</label>
                        <input v-model="info.email" type="text" id="form__email" class="form__input" name="email"
                               value="" required placeholder="info@domain.ru" autocomplete="off">
                    </div>
                    <div class="form__group">
                        <label for="form__promo" class="form__label">{{ msg[ lang ].label_promo }}</label>
                        <input v-model="info.promo" type="text" id="form__promo" class="form__input" name="promo"
                               value="" autocomplete="off">
                    </div>
                    <div class="form__group form__checkbox">
                        <input v-model="info.check" :checked="info.check" type="checkbox" id="form__checkbox"
                               name="checkbox" required>
                        <label for="form__checkbox" class="form__small-text" v-html="msg[lang].label_policy"></label>
                    </div>
                    <div class="form__group">
                        <input type="submit" id="form__submit" class="form__submit btn btn--primary btn--lg"
                               :value="msg[lang].send">
                    </div>
                </div>
                <div v-else class="form__success">
                    <p class="grenada-citizenship.ru text-center">{{ msg[ lang ].congrats }}</p>
                    <div class="form__image"></div>
                </div>
            </form>
        </div>

    </section>
</template>

<script>
import axios from "axios"

import { store } from "@/store"

export default {
    name: "FeedbackFormComp",
    data: function () {
        return {
            info: {
                phone: null,
                check: true,
                email: null,
                name: null,
                promo: null,
                sent: false
            },
            msg: {
                'ru-RU': {
                    subtitle: 'Индивидуальный расчет затрат на оформление',
                    paragraph: 'Узнайте сумму сопутствующих расходов, график платежей и стоимость услуг сопровождения при оформлении',
                    label_name: 'Вас зовут',
                    label_phone: 'Номер телефона',
                    label_promo: 'Кодовое слово',
                    label_policy: 'Я соглас(ен/на) <a href="javascript:void(0)" class="js-open-modal" data-modal="policy">политикой конфиденциальности</a> и с положением о порядке хранения персональных данных',
                    send: 'Заказать расчет затрат',
                    congrats: 'Спасибо, Ваша заявка принята!',
                },
                'en': {
                    subtitle: 'Individual calculation of registration costs',
                    paragraph: 'Find out the amount of associated costs, payment schedule and the cost of accompanying services during registration',
                    label_name: 'Your name',
                    label_phone: 'Telephone',
                    label_promo: 'Promo-code',
                    label_policy: 'I agree with the <a href="javascript:void(0)" class="js-open-modal" data-modal="policy">privacy policy</a> and with the regulation on the storage of personal data',
                    send: 'Order cost calculation',
                    congrats: 'Thank You! We will contact You soon',
                },
            },
            lang: store.getters.LOCALE.language
        }
    },
    methods: {

        sendEmail: function () {

            const url = 'https://citizen.gd/api/send'

            const params = {
                "name": this.info.name,
                "phone": this.info.phone,
                "email": this.info.email,
                "promo": this.info.promo,
            }

            try {
                axios( {
                    method: 'post',
                    url: url,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json, text/plain, */*',
                    },
                    data: params
                } )
                    .then(
                        res => {
                            this.sendInformation = res.data
                            console.log( this.sendInformation )
                            if ( this.sendInformation) {
                                this.info.phone = null
                                this.info.email = null
                                this.info.check = null
                                this.info.name = null
                                this.info.promo = null

                                this.info.sent = true
                            } else {
                                let errorDiv = document.querySelector( '.message--callback' )
                                errorDiv.innerHTML = 'Произошла ошибка при отправке.'
                                errorDiv.classList.remove( 'd-none' )
                                errorDiv.classList.add( 'message--error' )
                            }
                        }
                    )
            } catch ( error ) {
                this.error = error;
            }

        },

        sendFeedback: function () {

            let err = false
            let data = this.info
            let errorDiv = document.querySelector( '.message--callback' )

            data.received = new Date();

            let phoneRegExp = /^((\+7|7|8)+([0-9]){10})$/gm;

            let emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/igm

            let validPhone = phoneRegExp.test( this.info.phone )

            if ( validPhone === false ) {
                err = true
                errorDiv.innerHTML = 'Неверный формат телефона'
                errorDiv.classList.remove( 'd-none' )
                errorDiv.classList.add( 'message--error' )
            }
            let validEmail = emailRegExp.test( this.info.email )
            if ( validEmail === false ) {
                err = true
                errorDiv.innerHTML = 'Неверный формат e-mail'
                errorDiv.classList.remove( 'd-none' )
                errorDiv.classList.add( 'message--error' )
            }

            if ( err === false ) {

                axios.post( this.$api_url + '/feedbacks', data )
                    .then( () => {

                        this.sendEmail()

                    } )
                    .catch( ( error ) => {
                        console.log( error )
                        errorDiv.innerHTML = error
                        errorDiv.classList.remove( 'd-none' )
                        errorDiv.classList.add( 'message--error' )
                    } )
            }
        },
    }
}
</script>

<style scoped>

</style>
