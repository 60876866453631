<template>
    <div class="menu" data-sticky="true" data-sticky-offset="100" data-sticky-viewport="100" data-sticky-padding="50">
        <div class="container">
            <div class="menu__links">
                <a v-for="link in menu.links[lang]" :key="link.id" :href="link[1]" class="menu__link">
                    {{ link[ 0 ] }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { sticky } from '@/assets/js/sticky-blocks'

import { store } from "@/store"

export default {
    name: "MainMenuComp",
    props: {
        menu: {
            links: {}
        },
    },
    data () {
        return {
            lang: store.getters.LOCALE.language
        }
    },
    mounted () {
        sticky()
    }
}
</script>

<style scoped>

</style>
