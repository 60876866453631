import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import { store } from "./store"
import 'normalize.css'
import '@/assets/scss/main.scss'

Vue.config.productionTip = false
Vue.prototype.$api_url = 'https://api.citizen.gd'

router.beforeEach( async ( to, from, next ) => {
    const { title } = to.meta;
    const brand = "Grenada Embassy";
    document.title = `${ title ? title + " | " : "" }${ brand }`;
    next();
} );

new Vue( {
    router,
    store,
    render: ( h ) => h( App ),
} ).$mount( "#app" )
