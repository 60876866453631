<template>
    <div class="modals">
        <div class="modal scrollbar modal--small modal--rounded" data-modal="callback">
            <a href="javascript:void(0)" class="js-modal-close modal__cross">Закрыть</a>

            <CallbackComp/>

        </div>

        <div class="modal scrollbar modal--wide" data-modal="policy">

            <a href="javascript:void(0)" class="js-modal-close modal__cross">Закрыть</a>

            <div v-if="politics" class="modal__body" v-html="politics.text"></div>

        </div>

        <div class="overlay js-overlay-modal"></div>
    </div>
</template>

<script>
import axios from "axios"

import CallbackComp from './CallbackComp.vue'

export default {
    name: "ModalsComp",
    data () {
        return {
            politics: {
                text: ''
            },
            error: null,
        }
    },
    async created () {
        try {
            await axios
                .get( this.$api_url + '/politics' )
                .then( response => ( this.politics = response.data ) )
        } catch ( error ) {
            this.error = error;
        }
    },
    components: {
        CallbackComp,
    },
}
</script>

<style scoped>

</style>
