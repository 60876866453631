<template>
    <section id="advantages" class="advantages">
        <div class="container">
            <div class="advantages__grid d-grid">
                <div class="advantages__block">
                    <h2 class="advantages__title h2">{{ advantages.article }}</h2>
                    <div class="advantages__text text">{{ advantages.paragraph }}</div>
                    <a href="javascript:void(0)" class="btn btn--secondary js-open-modal"
                       data-modal="callback">{{ advantages.buttonText }}</a>
                </div>
                <div class="advantages__items d-grid">
                    <div v-for="advantage in advantages.content" :key="advantage.id"
                         class="advantages__item card card--outline">
                        <img class="advantages__item-img" :src="$api_url + advantage.icon.url" alt=""/>
                        <div class="advantages__item-name">{{ advantage.title }}</div>
                        <div class="advantages__item-paragraph">{{ advantage.paragraph }}</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios"

import { store } from "@/store";

export default {
    name: "AdvantagesComp",
    data () {
        return {
            advantages: {
                paragraph: '',
                buttonText: '',
                content: '',
                icon: {},
            },
            error: null,
        }
    },
    async beforeCreate () {
        try {
            await axios
                .get( this.$api_url + '/advantages?_locale=' + store.getters.LOCALE.language )
                .then( response => ( this.advantages = response.data ) )
        } catch ( error ) {
            this.error = error;
        }
    },
}
</script>

<style scoped>

</style>
