<template>
    <section id="citizenship" class="citizenship">
        <div class="container">
            <div class="citizenship__grid">
                <h3 class="citizenship__article text-center h3">{{ citizenship.article }}</h3>
                <div class="citizenship__preview text text-center" v-html="citizenship.paragraph"></div>
                <div class="citizenship__wrap">
                    <div class="citizenship__items d-grid">
                        <div v-for="(step, number) in citizenship.statement" :key="step.id"
                             class="citizenship__item card">
                            <div v-if="step.icon" class="citizenship__item-icon">
                                <img :src="$api_url + step.icon.url" alt=""/>
                            </div>
                            <div v-else class="citizenship__item-number">{{ number + 1 }}</div>
                            <div class="citizenship__item-name">{{ step.title }}</div>
                            <div class="citizenship__item-paragraph">{{ step.paragraph }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios"

import { store } from "@/store"

export default {
    name: "CitizenshipComp",
    data () {
        return {
            citizenship: {
                article: '',
                paragraph: '',
                statement: {}
            },
            error: null,
        }
    },
    async beforeCreate () {
        try {
            await axios
                .get( this.$api_url + '/citizenship?_locale=' + store.getters.LOCALE.language )
                .then( response => ( this.citizenship = response.data ) )
        } catch ( error ) {
            this.error = error;
        }
    }
}
</script>

<style scoped>

</style>
